.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App {
  height: 100vh;
  background: linear-gradient(to right, #f99608, #8400c9);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.version-text {
  position: absolute;
  top: 7%;
  left: 0%;
  margin-left: 20px;
  color: white;
  font-size: 36px;
  font-weight: bold;
  opacity: 90%;
}

.white-section {
  background-color: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.center-image {
  max-width: 20%;
  margin: 20px 0;
  margin-top: -50px;
}

.buttons-container {
  display: flex;
  margin-top: 90px;
  justify-content: space-around;
  width: 60%;
}

.button-container {
  text-align: center;
  margin-right: 50px;
  flex: 1;
}

.button {
  background-color: white;
  display: flex;
  align-items: center;
  padding: 20px;
  justify-content: center;
  flex-direction: row;
  border: 1px solid black;
  cursor: pointer;
  text-decoration: none;
  color: #282c34;
  font-weight: bold;
  transition: transform 0.1s ease;
}
.button:hover {
  transform: scale(1.1); /* Scales up the button by 10% on hover */
}

.button p {
  font-size: 0.75rem;
  margin-top: 10px;
}

.logo {
  height: 40px;
  margin-right: 15px;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.disabled .button {
  cursor: not-allowed;
}
.disabled .button {
  cursor: not-allowed;
}
.disabled .button:hover {
  transform: none; /* Prevents scaling on disabled buttons */
}

.footer {
  width: 100%;
  text-align: right;
  padding: 10px 0;
  position: absolute;
  bottom: 0;
}

.footer div {
  color: white;
  opacity: 70%;
  margin-right: 10px;
  font-size: 16px;
  padding: 2px; /* Slight padding for spacing */
}